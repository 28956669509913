import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Post from "./single";

class ComponentJournal extends React.Component {
  render() {
    const {
      data: {
        wordPress: {
          posts: { edges: posts },
        },
      },
    } = this.props;

    // console.log(posts);

    return (
      <section
        id="body"
        className="listing pt-5 p-bottom b-top b-bottom mt-0 relative"
      >
        <div className="container">
          <div className="row">
            {posts &&
              posts.map((post) => {
                // console.log(post.node.postId);
                return <Post key={post.node.postId} post={post.node} />;
              })}
          </div>
        </div>
      </section>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        wordPress {
          posts {
            edges {
              node {
                postId
                title
                acf_blog_author {
                  author
                  jobTitle
                }
                excerpt
                uri
                featuredImage {
                  ...fragmentJournalFeaturedImage
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <ComponentJournal data={data} />}
  />
);

export const fragmentJournalFeaturedImage = graphql`
  fragment fragmentJournalFeaturedImage on WordPress_MediaItem {
    altText
    sourceUrl
    srcSet
    content
    mediaItemId
    modified
    srcSet
    imageFile {
      extension
      publicURL
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
