import { graphql } from "gatsby";
import contentParser from "gatsby-wpgraphql-inline-images";
import React from "react";
import Posts from "../components/Journal/listing";
import SEO from "../components/SEO";
import Social from "../components/Social/sharer";
import Layout from "../layouts/home";
import { inlineUrls } from "../utils";



class Journal extends React.Component {
  render() {
    const {
      location,
      data: {
        wordPress: {
          pageBy: {
            title,
            acf_subtitle: { subtitle },
            excerpt,
          },
        },
      },
    } = this.props;

    // console.log(this.props);

    return (
      <Layout location={location} cssClass="journal">
        <SEO title={title} description={excerpt} />
        <section id="header" className="p-top p-bottom text-center bg-charcoal">
          <div className="featured">
            <h1>{title}</h1>
            <div className="lead">
              <p>{contentParser({ content: subtitle }, inlineUrls)}</p>
            </div>
          </div>
        </section>
        <section id="excerpt" className="bg-light-gray p-top p-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 lead line text-center">
                {contentParser({ content: excerpt }, inlineUrls)}
              </div>
            </div>
          </div>
        </section>
        <Social location={location} />
        <Posts />
      </Layout>
    );
  }
}

export default Journal;

export const journalQuery = graphql`
  {
    wordPress {
      pageBy(uri: "journal") {
        title
        acf_subtitle {
          subtitle
        }
        excerpt
      }
    }
  }
`;
