import React from "react";
import parse from "html-react-parser";
import { Link } from "gatsby";
import Img from "gatsby-image";

class Post extends React.Component {
  render() {
    const defaultPost = {
      post: {
        featuredImage: {
          imageFile: {
            publicURL: "/images/team/placeholder.jpg",
          },
        },
      },
    };

    let {
      post: {
        title,
        excerpt,
        uri: link,
        featuredImage,
        // acf_blog_author: { author, jobTitle },
      },
    } = this.props;

    // featuredImage = featuredImage || defaultPost.post.featuredImage;

    // console.log(view);

    return (
      <Link className="col-md-4 pt-5 post" to={`/${link}`} title="Read more">
        <div className="image">
          {featuredImage ? (
            <Img
              className="bg"
              fluid={featuredImage.imageFile.childImageSharp.fluid}
              alt="image"
              fadeIn={false}
            />
          ) : (
            <img
              className="bg"
              src={defaultPost.post.featuredImage.imageFile.publicURL}
              alt={parse(title)}
            />
          )}
        </div>
        <div className="content">
          <h3 className="mb-2">{title}</h3>
          {parse(excerpt)}
        </div>
      </Link>
    );
  }
}

export default Post;
